<template>
  <v-app-bar flat app rounded="0" height="60" id="iscNavbar" class="">
    <div
      class="d-flex align-center justify-space-between"
      style="width: calc(100% - 60px)"
    >
      <div>
        <div class="ml-6">
          <nav class="mainNav d-flex justify-space-around">
            <a
              :href="urlListen"
              :class="module == 'listen' ? 'navSelected' : ''"
              >LISTEN</a
            >
            <a
              :href="urlMonitor"
              :class="module == 'monitor' ? 'navSelected' : ''"
              >MONITOR</a
            >
            <a
              :href="urlDesign"
              :class="module == 'design' ? 'navSelected' : ''"
              >DESIGN</a
            >
            <a
              :href="urlAdvise"
              :class="module == 'advise' ? 'navSelected' : ''"
              >ADVISE</a
            >
          </nav>
        </div>
      </div>
      <div class="d-flex align-center justify-center">
        <div class="userText d-flex">
          <span>
            Welcome back, <b>{{ nombreUsuario }}</b></span
          >
        </div>
      </div>
    </div>
    <div class="userImage d-flex align-center justify-center mx-2">
      <img
        @click="showModal = true"
        class="profile_img"
        :src="url"
        alt="foto de perfil"
      />
    </div>
    <div class="userSettings d-flex align-center justify-center">
      <div class="button-config" @click="toggleDropdown">
        <i class="fa fa-cog fa-2x"></i>
      </div>
    </div>
    <div class="dropdown-core" v-show="openedDropdown">
      <a :href="link.url" v-for="(link, index) in links" :key="index">
        <i :class="link.icon"></i> <span>{{ link.label }}</span>
      </a>
      <a @click="Logout" style="cursor: pointer">
        <i class="fa fa-power-off"></i> <span>Logout</span>
      </a>
    </div>
    <v-dialog
      v-model="showModal"
      width="500px"
      content-class="dialogImage"
      v-on:click:outside="closeImageModal"
    >
      <v-card width="500px">
        <div class="d-flex justify-space-between">
          <div>
            <v-card-title style="font-size: 20px"> Upload Logo </v-card-title>
          </div>
          <div class="pt-3 pr-3">
            <v-btn icon dark style="color: darkgray" @click="closeImageModal">
              <v-icon size="20">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider light></v-divider>
        <div
          class="d-flex flex-column justify-center align-center"
          style="padding: 1rem"
        >
          <div>
            <img
              @click="showModal = true"
              class="profile_img_modal mt-5"
              :src="url"
              alt="foto de perfil"
            />
          </div>
          <div class="d-flex flex-column" style="width: 60%">
            <div class="align-self-start">
              <p class="mb-0" style="font-size: 17px; text-align: center">
                Select File
              </p>
            </div>
            <div class="align-self-start mb-10">
              <input
                type="file"
                @change="uploadFile"
                ref="inputProfileImage"
                style="font-size: 13px"
              />
              <span class="mb-4" style="color: red; font-size: 14px">
                {{ textoValidacion }}
              </span>
            </div>
          </div>
          <!-- <div class="">
            <p class="mb-4 " style="font-size: 17px">Select file</p>
            <input
              type="file"
              class="mb-12"
              @change="uploadFile"
              ref="inputProfileImage"
            />
            <p class="text-xs mb-4" style="color: red">
              {{ textoValidacion }}
            </p>
          </div> -->
        </div>
        <v-divider light></v-divider>
        <div class="d-flex justify-end pa-4">
          <v-btn
            depressed
            light
            style="margin-right: 7px"
            @click="closeImageModal"
            >Close</v-btn
          >
          <v-btn
            depressed
            class="btnUploadFile"
            @click="updateImageProfile"
            :disabled="inputDisabled"
            >Upload File</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "Header",
  data() {
    return {
      url: require("@/assets/icons/usuario-desconocido.jpg"),
      links: [
        {
          icon: "fa fa-user",
          url: `${process.env.VUE_APP_MONITOR_URL}/settings/profile`,
          label: "Profile",
        },
        {
          icon: "fa fa-cog",
          url: `${process.env.VUE_APP_MONITOR_URL}/settings/settings`,
          label: "Settings",
        },
        {
          icon: "fa fa-th-large",
          url: `${process.env.VUE_APP_MONITOR_URL}/settings/quickstar`,
          label: "Quick Start",
        },
      ],
      nombreUsuario: "User",
      showModal: false,
      openedDropdown: false,
      inputDisabled: true,
      textoValidacion: "",
      url: require("@/assets/icons/usuario-desconocido.jpg"),
      urlAnterior: null,
      objUsuario: {},
      token: null,
      refresh: null,
      module: null,
      username: null,
      initWidth: null,
    };
  },
  computed: {
    urlMonitor(){
      return `${process.env.VUE_APP_MONITOR_URL}/overview/accounts`
    },
    urlListen() {
      return `${process.env.VUE_APP_LISTEN_URL}/auth?token=${this.token}&refresh=${this.refresh}`;
    },
    urlDesign() {
      return `${process.env.VUE_APP_DESIGN_URL}/auth?token=${this.token}&refresh=${this.refresh}`;
    },
    urlAdvise() {
      return `${process.env.VUE_APP_ADVISE_URL}/auth?token=${this.token}&refresh=${this.refresh}`;
    },
  },
  methods: {
    /* checkModule(mod) {
      const module = sessionStorage.getItem("module");
      return module == mod ? "navSelected" : "";
    }, */
    getDimensions() {
      const resizeContent = document.getElementById('ResizeModal');
      this.initWidth = document.documentElement.clientWidth;
      (this.initWidth < 1024) ? resizeContent.style.display = 'flex': resizeContent.style.display = 'none'
    },
    toggleDropdown() {
      this.openedDropdown = !this.openedDropdown;
    },
    hideDropdown() {
      this.openedDropdown ? (this.openedDropdown = false) : null;
    },
    Logout() {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = `${process.env.VUE_APP_MONITOR_URL}/login`;
    },
    async uploadFile(evento) {
      this.textoValidacion = "";
      // console.log('hubo un cambio en el upload')
      // console.log('hubo un cambio en el upload event: ',evento.target.files[0])
      let arrFiles = Array.from(evento.target.files);
      // console.log('el arrFiles: ',arrFiles)
      let arrFilesBlob = [];
      let arrNombresBlob = [];
      arrFiles.forEach((item) => {
        arrNombresBlob.push(item["name"]);
        arrFilesBlob.push(this.handleFile(item));
      });
      // console.log('los arrNombresBlob : ',arrNombresBlob)
      const nombresSplit = arrNombresBlob[0].split(".");
      const extencion = nombresSplit[nombresSplit.length - 1].toLowerCase();
      if (
        !(
          extencion === "jpg" ||
          extencion === "jpeg" ||
          extencion === "png" ||
          extencion === "png"
        )
      ) {
        this.textoValidacion = "La imagen no tiene el formato correcto.";
        this.inputDisabled = true;
        return;
      } else {
        this.inputDisabled = false;
      }
      const respPromiseAll = await Promise.all(arrFilesBlob);
      this.url = respPromiseAll[0];
    },
    cancelarSubida() {
      if (this.urlAnterior) {
        this.url = this.urlAnterior;
      }
    },
    handleFile(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async updateImageProfile() {
      try {
        Swal.fire({
          title: `Loading`,
          text: "Uploading Image!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-primary",
          showConfirmButton: false,
          closeModal: false,
          icon: "info",
        });
        if (this.textoValidacion !== "") {
          this.textoValidacion =
            "No se puede subir la imagen sin antes corregir el formato.";
          this.inputDisabled = true;
        }
        let dataUpdateProfile = {};
        dataUpdateProfile.ID_USUARIO = this.objUsuario.ID_USUARIO;
        dataUpdateProfile.dsc_img_profile = this.url;
        const urlSplit = this.url.split("/");
        if (urlSplit[0] === "data:image") {
          let response = await (
            await fetch(
              /* "https://oefdxfa72j.execute-api.us-east-1.amazonaws.com/dev/login/user/updateImageProfile", */
              process.env.VUE_APP_UPDATE_IMAGE,//"https://l70nzl4bbh.execute-api.us-east-1.amazonaws.com/prod/login/user/updateImageProfile",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(dataUpdateProfile),
              }
            )
          ).json();

          if (response.code == 0) {
            Swal.fire({
              title: `Success`,
              text: "Uploaded Image!",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-primary",
              icon: "success",
              focusConfirm: false,
            }).then(() => {
              this.urlAnterior = this.url;
              this.closeOnlyImageModal();
            });
          }
        }
      } catch (error) {
        Swal.fire({
          title: `Error`,
          text: "There was a problem uploading the image!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-primary",
          icon: "warning",
        });
      }
    },
    resetModal() {
      this.textoValidacion = "";
    },
    closeOnlyImageModal() {
      this.inputDisabled = true;
      this.showModal = false;
      this.$refs.inputProfileImage.value = null;
    },
    closeImageModal() {
      this.inputDisabled = true;
      this.showModal = false;
      this.cancelarSubida();
      this.$refs.inputProfileImage.value = null;
    },
    async getObjUsuario() {
      if (sessionStorage.getItem("username")) {
        const settings = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            DSC_USERNAME: sessionStorage.getItem("username"),
          }),
        };
        try {
          const { obj } = await (
            await fetch(
              `${process.env.VUE_APP_GET_USERINFO}`,//"https://l70nzl4bbh.execute-api.us-east-1.amazonaws.com/prod/login/user/getUserbyId", //monitor.isocialcube.com
              settings
            )
          ).json();
          if (!obj) {
            throw new Error("Error al obtener info del usuario");
          } else {
            this.$store.dispatch("user/setObjUsuario", obj);
            this.objUsuario = obj;
            if (obj.dsc_img_profile) {
              this.url = obj.dsc_img_profile;
              this.urlAnterior = obj.dsc_img_profile;
            } else {
              this.urlAnterior = this.url;
            }
            this.token = sessionStorage.getItem('token');
            this.refresh = sessionStorage.getItem('refresh');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        if (this.username != null) {
          const settings = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              DSC_USERNAME: this.username,
            }),
          };
          try {
            const { obj } = await (
              await fetch(
                url_user_info,//"https://l70nzl4bbh.execute-api.us-east-1.amazonaws.com/prod/login/user/getUserbyId", //monitor.isocialcube.com
                settings
              )
            ).json();
            if (!obj) {
              throw new Error("Error al obtener info del usuario");
            } else {
              this.$store.dispatch("user/setObjUsuario", obj);
              this.objUsuario = obj;
              if (obj.dsc_img_profile) {
                this.url = obj.dsc_img_profile;
                this.urlAnterior = obj.dsc_img_profile;
              } else {
                this.urlAnterior = this.url;
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
  },
  created() {
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);    
  },
  async mounted() {
    window.addEventListener("tokens-changed", async (event) => {
      this.token = event.detail.token;
      this.refresh = event.detail.refresh;

      window.addEventListener("user-changed", async (event) => {
        this.nombreUsuario = event.detail.name;
        this.username = event.detail.username;
        this.module = event.detail.module;
        await this.getObjUsuario();
      });
    });
    if (!this.username && sessionStorage.getItem("username")) {
      await this.getObjUsuario();
    }
  },
  watch: {
    "this.$store.getters['user/getObjUsuario']": {
      immediate: true,
      handler: function () {
        const obj = this.$store.getters["user/getObjUsuario"];
        if (obj) {
          this.nombreUsuario = obj.DSC_NOMBRES;
        } else {
          this.nombreUsuario = sessionStorage.getItem("name");
        }
        this.module = sessionStorage.getItem("module");
      },
    },
  },
};
</script>

<style scoped>
/* .mainNav {
  line-height: 35px;
  text-align: center;
  color: white;
  font-weight: 600;
} */
#iscNavbar {
  width: calc(100% - 70px);
  background-color: #00a5ff;
  margin-left: 70px;
  font-family: 'Oxygen', 'Roboto';
}

#iscNavbar >>> .v-toolbar__content {
  padding: 0px !important;
}

/* Modules */

.mainNav a {
  font-size: 12px;
  line-height: 35px;
  padding: 0 20px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0);
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  text-decoration: none;
}

.mainNav a:focus {
  outline: none;
}

.mainNav a:hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  background-color: transparent;
  color: #ffffff;
}

.mainNav a.navSelected {
  border-radius: 3px;
  color: #00a5ff;
  background-color: white;
}

/* Profile Picture */
.userImage {
  height: 50px;
  width: 50px;
}

.profile_img {
  border-radius: 100%;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.userText {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
}
.userText span {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: white;
}

.userSettings {
  width: 70px;
  height: 60px;
  background-color: #273444;
  height: 100%;
}

.button-config {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-config i {
  font-size: 25px;
  color: #96a0b8;
}

.dropdown-core {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 50;
  padding: 35px 0;
  top: 60px;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background: #f1f1f1 url("../assets/icons/logofullv.png") no-repeat scroll
    177px 66px;
}

.dropdown-core a {
  display: block;
  font-size: 12px;
  text-align: left;
  padding: 10px 20px 20px 20px;
  color: #566b8a;
  transition: all ease 0.2s;
  text-decoration: none;
}

.dropdown-core a:hover {
  color: #00a5ff;
}

.dropdown-core a i {
  font-size: 16px;
  margin-right: 1rem;
}

.btnUploadFile {
  background-color: #00a5ff !important;
  color: white;
}

/* Modal */

.profile_img_modal {
  border-radius: 100%;
  width: 150px;
  height: 150px;
}

* >>> .dialogImage {
  align-self: flex-start;
}
</style>